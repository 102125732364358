<template>
  <div class="components__editor">
    <div :id="editorId"></div>
  </div>
</template>

<script>
import E from 'wangeditor'
import { baseUrl, isDev } from '@/config/env'
import { uid } from 'uid'

export default {
  model: {
    prop: 'content',
    event: 'change'
  },
  props: {
    token: String,
    content: String
  },
  data() {
    return {
      editor: null,
      editorId: 'editor' + uid()
    }
  },
  watch: {
    content(v) {
      if (this.editor) {
        this.editor.txt.html(v)
      }
    }
  },
  async mounted() {
    await this.$nextTick()
    await this.initEditor()
  },
  methods: {
    initEditor() {
      this.editor = new E('#' + this.editorId)
      this.configEditor()
      this.editor.create()
    },
    configEditor() {
      const editor = this.editor
      editor.config.uploadImgServer = (isDev ? '' : baseUrl) + '/api/upload'
      editor.config.uploadFileName = 'file'
      editor.config.uploadImgMaxLength = 1
      this.editor.config.zIndex = 0
      editor.config.uploadImgHooks = {
        customInsert: (insertImgFn, result) => {
          console.log(result.data, 'result')
          insertImgFn(result.data)
        }
      }
      editor.config.uploadImgHeaders = {
        token: this.token
      }
      editor.config.onchange = () => {
        const content = this.getContent()
        this.$emit('change', content)
      }
    },
    getContent() {
      return this.editor.txt.html()
    }
  }
}
</script>

<style lang="scss" scoped>
// .components__editor {
//   ::v-deep {
//     .w-e-toolbar {
//       z-index: 0 !important;
//     }
//     .w-e-text-container {
//       z-index: 0 !important;
//     }
//   }
// }
</style>
