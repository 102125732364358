<template>
  <div class="com-upload">
    <el-upload
      :headers="headers"
      :action="action"
      :on-success="success"
      :before-upload="beforeElUpload"
      :on-remove="remove"
      :file-list="fileList"
      list-type="picture-card"
      :class="['upload', { 'hide-upload': hideUpload }]"
      :limit="limit"
      :show-file-list="true"
    >
      <i class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }" v-if="type !== 'img'">
        <div class="com-upload__thumbnail">
          <img v-if="type === 'zip'" src="@/assets/images/zip.png" />
          <img v-if="type === 'file'" src="@/assets/images/file.png" />
          <video v-if="type === 'video'" :src="file.url"></video>
          <p>{{ file.name }}</p>
        </div>
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="remove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { baseUrl, isDev } from '@/config/env'

export default {
  props: {
    type: {
      type: String,
      default: 'img' // img zip
    },
    file: [Object, Array],
    token: String,
    limit: {
      type: Number,
      default: 1
    },
    beforeUpload: Function
  },
  model: {
    prop: 'file',
    event: 'change'
  },
  data() {
    return {
      action: (isDev ? '' : baseUrl) + '/api/upload'
    }
  },
  computed: {
    headers() {
      return {
        token: this.token
      }
    },
    fileList() {
      if (Array.isArray(this.file)) {
        return this.file
      }
      if (this.file?.url) return [this.file]
      return []
    },
    hideUpload() {
      return this.fileList.length >= this.limit
    }
  },
  methods: {
    beforeElUpload(file) {
      if (this.beforeUpload) {
        return this.beforeUpload(file)
      }
      return true
    },
    success(_, file) {
      if (this.limit === 1) {
        this.$emit('change', file)
        return
      }
      this.$emit('change', [...this.file, file])
    },
    remove(file) {
      if (this.limit === 1) {
        this.$emit('change', null)
        return
      }
      const removeIndex = this.file.findIndex((item) => item.uid === file.uid)
      if (removeIndex > -1) {
        this.$emit('change', [
          ...this.file.slice(0, removeIndex),
          ...this.file.slice(removeIndex + 1)
        ])
      }
    }
  }
}
</script>

<style scoped lang="scss">
.com-upload {
  ::v-deep {
    .hide-upload .el-upload {
      display: none;
    }
  }
}

.com-upload__thumbnail {
  img {
    width: 60px;
    height: 60px;
    display: block;
    margin: 30px auto 10px;
  }
  p {
    text-align: center;
    font-size: 14px;
    color: #666;
    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.com-upload-file-thumbnail {
  width: 80px;
  height: 80px;
  display: block;
  margin: 30px auto;
}
</style>
